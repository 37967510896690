import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';

/**
 * Duplicated in _icon.scss.
 */
const getColor = color => {
  if (!color) {
    return;
  }
  if (color === 'dark') {
    return tokens.color['neutral-7'];
  }
  if (color === 'brand') {
    return;
  }
  return tokens.color[color];
};
const getSize = size => {
  if (!size) {
    return '1em';
  }
  return tokens.space[size];
};
const getStrokeWidth = size => {
  if (!size || size === 'md') {
    return '1.5';
  }
  if (size === 'xl') {
    return '1.2';
  }
  if (size === 'xxl') {
    return '1';
  }
  if (size === 'xxxl' || size === 'layout-lg') {
    return '0.75';
  }
  if (size === 'layout-xl') {
    return '0.5';
  }
  return '1.25';
};
const StyledIcon = styled.svg.withConfig({
  componentId: "sg16120__sc-556k3u-0"
})(["color:", ";display:", ";fill:none;height:", ";position:relative;stroke:currentColor;stroke-width:", ";top:", ";vertical-align:baseline;width:", ";&[data-icon$='-fill']{fill:currentColor;stroke:none;}", ""], props => getColor(props.$color), props => props.$block && 'block', props => getSize(props.$size), props => getStrokeWidth(props.$size), props => props.$baseline && '0.125em', props => getSize(props.$size), props => props.$color === 'brand' && css(["&[data-icon='te-palvelut-fill']{color:#b7c000;}"]));

export { StyledIcon };
