import tokens from '@te-digi/styleguide-tokens';
import styled, { css } from 'styled-components';
import { getResponsiveStyle } from '../utils/styles/get-responsive-style.js';

const getColor = color => {
  if (color === 'dark') {
    return 'neutral-7';
  } else if (color === 'success') {
    return 'success-dark';
  } else if (color === 'warning') {
    return 'warning-dark';
  } else {
    return color;
  }
};
const StyledText = styled.span.withConfig({
  componentId: "sg16120__sc-1d9z2dt-0"
})(["color:", ";display:", ";font-style:", ";font-weight:", ";line-height:", ";margin-bottom:0;overflow:", ";text-overflow:", ";text-transform:", ";white-space:", ";", " ", " ", " ", ""], _ref => {
  let {
    $color,
    $variant
  } = _ref;
  if ($variant === 'suffix') {
    return tokens.color['neutral-7'];
  } else if ($color) {
    return tokens.color[getColor($color)];
  } else {
    return undefined;
  }
}, _ref2 => {
  let {
    $block
  } = _ref2;
  return $block ? 'block' : undefined;
}, _ref3 => {
  let {
    $fontStyle
  } = _ref3;
  return $fontStyle;
}, _ref4 => {
  let {
    $fontWeight
  } = _ref4;
  return $fontWeight ? tokens['font-weight'][$fontWeight] : undefined;
}, _ref5 => {
  let {
    $lineHeight
  } = _ref5;
  return $lineHeight ? tokens['line-height'][$lineHeight] : undefined;
}, props => props.$overflow, props => props.$textOverflow, _ref6 => {
  let {
    $transform
  } = _ref6;
  return $transform;
}, _ref7 => {
  let {
    $whiteSpace
  } = _ref7;
  return $whiteSpace;
}, props => getResponsiveStyle('font-size', props.$size), props => getResponsiveStyle('text-align', props.$align), props => props.$lines && css(["-webkit-box-orient:vertical;-webkit-line-clamp:", ";display:-webkit-box;hyphens:auto;line-clamp:", ";overflow:hidden;"], props.$lines, props.$lines), props => props.$variant === 'suffix' && css(["&:before{color:", ";content:'|';padding:0 ", ";}"], tokens.color['neutral-4'], tokens.space.md));

export { StyledText };
