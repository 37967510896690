import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { BaseButton } from './BaseButton.js';
import { StyledButtonSpinnerContainer, StyledBaseButtonInput } from './BaseButton.style.js';
import { media } from '../utils/styles/media.js';
import { Text } from '../Text/Text.js';

const StyledButton = styled(BaseButton).withConfig({
  componentId: "sg16120__sc-15bkejm-0"
})(["align-items:center;background-color:transparent;border-radius:1.5rem;border:1px solid transparent;color:", ";cursor:pointer;display:inline-flex;font-size:", ";font-weight:", ";height:3rem;justify-content:center;line-height:", ";min-width:7rem;padding:0;position:relative;text-align:center;text-decoration:none;transition:box-shadow ", ",background-color ", ";user-select:none;vertical-align:middle;white-space:nowrap;&[aria-busy='true'] > :not(", "){visibility:hidden;}", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " &:hover,&:focus{text-decoration:none;", "}", ":focus ~ &,&:focus{box-shadow:", ";outline:0;}&:active{", "}"], tokens.color['neutral-9'], tokens['font-size'].md, tokens['font-weight'].normal, tokens['line-height'].xs, tokens.transition.fast, tokens.transition.fast, StyledButtonSpinnerContainer, props => props.$hasMaxWidth && css(["max-width:20rem;"]), props => props.$variant !== 'plain' && props.$shape !== 'circle' && !props.$size && css(["padding-left:", ";padding-right:", ";"], props.$hasIconLeft ? '1.25rem' : '1.75rem', props.$hasIconRight ? '1.25rem' : '1.75rem'), props => props.$color && css(["background-color:", ";border-color:", ";color:", ";"], tokens.color[props.$color], props.$color === 'dark' ? tokens.color['neutral-8'] : tokens.color[props.$color], props.$color === 'white' ? tokens.color.primary : tokens.color.white), props => props.$shape === 'circle' && css(["border-radius:", ";flex:0 0 auto;min-width:0;width:3rem;"], tokens.radius.circle), props => props.$shape === 'square' && css(["border-radius:", ";"], tokens.radius.md), props => props.$variant === 'outline' && css(["background-color:", ";color:", ";"], props.$color === 'white' ? 'transparent' : tokens.color.white, props.$color === 'dark' ? tokens.color['neutral-8'] : tokens.color[props.$color]), props => {
  let color;
  if (props.$color === 'danger') {
    color = tokens.color['danger-dark'];
  } else if (props.$color === 'dark') {
    color = tokens.color['neutral-8'];
  } else {
    color = tokens.color[props.$color];
  }
  return props.$variant === 'plain' && css(["background-color:transparent;border:0;color:", ";"], color);
}, props => {
  let color;
  if (props.$color === 'danger') {
    color = tokens.color['danger-dark'];
  } else if (props.$color === 'dark') {
    color = tokens.color['neutral-8'];
  } else {
    color = tokens.color[props.$color];
  }
  return props.$variant === 'plain' && props.$shape !== 'circle' && css(["background-color:transparent;border:0;border-radius:", ";color:", ";height:auto;line-height:inherit;max-width:none;min-width:auto;text-align:left;padding:0 0.125rem;"], tokens.radius.md, color);
}, props => props.$variant === 'plain' && css(["background-color:transparent;border:0;color:", ";"], props.$color === 'dark' ? tokens.color['neutral-8'] : tokens.color[props.$color]), props => props.$variant === 'plain' && !props.$hasIconLeft && !props.$hasIconRight && css(["padding-right:0;"]), props => props.$size === 'xs' && css(["font-size:", ";height:2rem;min-width:auto;width:", ";"], tokens['font-size'].sm, props.$shape === 'circle' ? '2rem' : undefined), props => props.$size === 'xs' && props.$shape !== 'circle' && css(["padding-left:", ";padding-right:", ";"], props.$hasIconLeft ? '0.5rem' : '1rem', props.$hasIconRight ? '0.5rem' : '1rem'), props => props.$size === 'sm' && css(["height:2.5rem;min-width:auto;width:", ";"], props.$shape === 'circle' ? '2.5rem' : undefined), props => props.$size === 'sm' && props.$shape !== 'circle' && css(["padding-left:", ";padding-right:", ";"], props.$hasIconLeft ? '0.5rem' : '1rem', props.$hasIconRight ? '0.5rem' : '1rem'), props => props.$size === 'xxs' && css(["height:1.5rem;min-width:auto;width:", ";"], props.$shape === 'circle' ? '1.5rem' : undefined), props => props.$disabled && css(["background-color:", ";border-color:", ";color:", ";cursor:default;pointer-events:none;"], props.$variant === 'plain' || props.$variant === 'outline' && props.$color === 'white' ? undefined : tokens.color['neutral-2'], tokens.color['neutral-5'], tokens.color['neutral-6']), props => {
  if (props.$disableHover) {
    return;
  }
  let backgroundColor = tokens.color["".concat(props.$color, "-light")];
  let borderColor = tokens.color["".concat(props.$color, "-light")];
  let color = tokens.color.white;
  switch (props.$variant) {
    case 'outline':
      if (props.$color === 'dark') {
        backgroundColor = tokens.color['neutral-7'];
        borderColor = tokens.color['neutral-7'];
      } else if (props.$color === 'white') {
        backgroundColor = tokens.color.white;
        color = tokens.color['primary-light'];
      }
      return css(["background-color:", ";border-color:", ";color:", ";"], backgroundColor, borderColor, color);
    case 'plain':
      if (props.$color === 'dark') {
        backgroundColor = tokens.color['neutral-4'];
        color = tokens.color['neutral-8'];
      } else if (props.$color === 'danger') {
        backgroundColor = tokens.color['danger-lightest'];
        color = tokens.color['danger-dark'];
      } else if (props.$color === 'white') {
        backgroundColor = tokens.color.white;
        color = tokens.color.primary;
      } else {
        backgroundColor = tokens.color["".concat(props.$color, "-lightest")];
        color = tokens.color["".concat(props.$color)];
      }
      return css(["background-color:", ";color:", ";"], backgroundColor, color);
    default:
      if (props.$color === 'white') {
        color = tokens.color['primary-light'];
      }
      return css(["background-color:", ";border-color:", ";color:", ";"], backgroundColor, borderColor, color);
  }
}, StyledBaseButtonInput, tokens.shadow.focus, props => {
  if (props.$disableHover) {
    return;
  }
  let backgroundColor = tokens.color["".concat(props.$color, "-dark")];
  let borderColor = tokens.color["".concat(props.$color, "-dark")];
  let color = tokens.color.white;
  switch (props.$variant) {
    case 'outline':
      if (props.$color === 'dark') {
        backgroundColor = tokens.color['neutral-8'];
        borderColor = tokens.color['neutral-8'];
      } else if (props.$color === 'white') {
        backgroundColor = tokens.color['neutral-3'];
        borderColor = tokens.color['neutral-3'];
        color = tokens.color['primary-dark'];
      }
      return css(["background-color:", ";border-color:", ";color:", ";"], backgroundColor, borderColor, color);
    case 'plain':
      if (props.$color === 'dark') {
        backgroundColor = tokens.color['neutral-4'];
        color = tokens.color['neutral-8'];
      } else if (props.$color === 'white') {
        backgroundColor = tokens.color.white;
        color = tokens.color.primary;
      } else {
        backgroundColor = tokens.color["".concat(props.$color, "-lightest")];
        color = tokens.color["".concat(props.$color)];
      }
      return css(["background-color:", ";color:", ";"], backgroundColor, color);
    default:
      return css(["background-color:", ";border-color:", ";color:", ";"], backgroundColor, borderColor, color);
  }
});
const StyledButtonLabel = styled.span.withConfig({
  componentId: "sg16120__sc-15bkejm-1"
})(["overflow:hidden;text-align:center;text-overflow:ellipsis;width:100%;", ""], props => props.$withSubLabel && css(["", "{text-align:left;}"], media.lg));
const StyledButtonSubLabel = styled(Text).withConfig({
  componentId: "sg16120__sc-15bkejm-2"
})([""]);
const StyledButtonIconLeftContainer = styled.span.withConfig({
  componentId: "sg16120__sc-15bkejm-3"
})(["display:flex;flex:0 0 auto;margin-right:", ";pointer-events:none;"], tokens.space.xs);
const StyledButtonIconRightContainer = styled.span.withConfig({
  componentId: "sg16120__sc-15bkejm-4"
})(["display:flex;flex:0 0 auto;margin-left:", ";pointer-events:none;"], tokens.space.xs);
const StyledButtonIconContainer = styled.span.withConfig({
  componentId: "sg16120__sc-15bkejm-5"
})(["display:flex;flex:0 0 auto;"]);
const StyledButtonBadgeIconContainer = styled.span.withConfig({
  componentId: "sg16120__sc-15bkejm-6"
})(["align-items:center;background-color:", ";border-radius:", ";color:", ";display:flex;height:", ";justify-content:center;position:absolute;right:-", ";top:-", ";width:", ";", ""], tokens.color.secondary, tokens.radius.circle, tokens.color.white, tokens.space.md, tokens.space.xxs, tokens.space.xxs, tokens.space.md, props => props.$size === 'xs' && css(["height:", ";width:", ";"], tokens.space.sm, tokens.space.sm));
const StyledHeaderButton = styled(StyledButton).withConfig({
  componentId: "sg16120__sc-15bkejm-7"
})(["max-width:14rem;", "{background-color:transparent;border:0;color:", ";flex-direction:column;height:100%;line-height:", ";min-width:0;padding:0;width:4rem;&:focus{background-color:transparent;box-shadow:none;}&:hover,&:active{background:transparent;color:", ";}", "{font-size:", ";}", ",", "{display:none;}}"], media.downMd, tokens.color.white, tokens['line-height'].xs, tokens.color.white, StyledButtonLabel, tokens['font-size'].sm, StyledButtonSubLabel, StyledButtonIconRightContainer);
const StyledHeaderButtonIconContainer = styled.span.withConfig({
  componentId: "sg16120__sc-15bkejm-8"
})(["align-items:center;display:flex;flex:0 0 auto;height:2.5rem;justify-content:center;margin-bottom:", ";width:2.5rem;", "{", ":hover &{background-color:", ";border-radius:", ";}", ":active &{background-color:", ";}}", "{background-color:transparent;margin-bottom:0;margin-left:-", ";}"], tokens.space.xxs, media.downMd, StyledHeaderButton, tokens.color['secondary-light'], tokens.radius.circle, StyledHeaderButton, tokens.color['secondary-dark'], media.lg, tokens.space.md);

export { StyledButton, StyledButtonBadgeIconContainer, StyledButtonIconContainer, StyledButtonIconLeftContainer, StyledButtonIconRightContainer, StyledButtonLabel, StyledButtonSubLabel, StyledHeaderButton, StyledHeaderButtonIconContainer };
