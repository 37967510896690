import { ElementType } from 'react'
import {
  BoxesIcon,
  CheckCircleIcon,
  DialogueIcon,
  ExternalLinkIcon,
  FileTextIcon,
  HandIcon,
  ImageIcon,
  InfoCircleIcon,
  LettersIcon,
  ListUnorderedIcon,
  PenIcon,
  PuzzlePieceIcon,
  ShapesIcon,
  StarIcon,
  TaskIcon,
  UserIcon
} from '@te-digi/styleguide'

import { slugify } from './slugify'

const getChildren = (items: Omit<Page, 'to'>[], prefix: string) =>
  items.map(item => ({
    ...item,
    to: `/${prefix}/${slugify(item.title)}/`
  }))

export type Page = {
  children?: Page[]
  description?: string
  external?: boolean
  icon?: ElementType
  title: string
  to: string
}

export const PAGES: Page[] = [
  {
    title: 'Käyttöönotto',
    to: '/kayttoonotto/',
    icon: InfoCircleIcon
  },
  {
    title: 'Design tokens',
    to: '/design-tokens/',
    icon: ShapesIcon
  },
  {
    title: 'Saavutettavuus',
    to: '/saavutettavuus/',
    icon: UserIcon,
    children: getChildren(
      [{ title: 'UX-suunnittelijoille' }, { title: 'Kehittäjille' }],
      'saavutettavuus'
    )
  },
  {
    title: 'Patternit',
    to: '/patternit/',
    icon: TaskIcon,
    children: getChildren(
      [
        {
          title: 'Lomake',
          description:
            'Lomake on joukko komponentteja, joilla käyttäjä syöttää tai valitsee tietoja.'
        },
        {
          title: 'Lomakekentän otsikointi',
          description:
            'Lomakekentän ohessa ohjeistetaan selkeästi mitä tietoa ja missä muodossa käyttäjän odotetaan siihen syöttävän tai valitsevan.'
        },
        {
          title: 'Lomakkeen toiminnot',
          description:
            'Lomakkeen käsittelyyn liittyvät toimintopainikkeet kootaan lomakenäkymän alaosaan painikkeet-alueelle.'
        },
        {
          title: 'Lomakkeen virheenkäsittely',
          description:
            'Käyttäjälle ilmoitetaan virheistä lomakkeelle syötetyissä tiedossa tai pakollisten tietojen puuttumisesta heti virheen tapahduttua ja ennen lomakkeen tallentamista tai lähettämistä.'
        },
        {
          title: 'Monisivuinen lomake',
          description:
            'Pitkät tai useista vaiheista koostuvat lomakkeet pilkotaan useammalle sivulle selkeiksi asiakokonaisuuksiksi tai vaiheiksi.'
        },
        {
          title: 'Monisivuisen lomakkeen navigaatio',
          description:
            'Monisivuisella lomakkeen käyttäjä voi siirtyä lomakkeen sivulta toiselle.'
        },
        {
          title: 'Hakulomake',
          description:
            'Hakulomakkeella käyttäjä voi hakea tietoja vapaalla hakusanalla ja suodattaa hakutuloksia rakenteisilla hakuehdoilla'
        },
        {
          title: 'Navigointi pois lomakkeelta',
          description:
            'Jos käyttäjä navigoi pois lomakkeelta yleisiä navigointireittejä käyttäen, häntä varoitetaan keskeneräisestä lomakkeesta.'
        },
        //        { title: '-' },
        {
          title: 'Toimintopainikkeet',
          description:
            'Toimintopainikkeella käyttäjä suorittaa jonkin toiminnon, kuten lomakkeen lähettämisen.'
        },
        {
          title: 'Tallenna-toiminto',
          description:
            'Toimintopainike jolla käyttäjä tallentaa lomakkeelle täytetyt tiedot tietokantaan.'
        },
        {
          title: 'Julkaise-toiminto',
          description:
            'Toimintopainike jolla käyttäjä tallentaa lomakkeelle täytetyt tiedot tietokantaan ja julkaisee ne Työmarkkinatorilla yleisesti nähtäväksi.'
        },
        {
          title: 'Lähetä-toiminto',
          description:
            'Toimintopainike jolla käyttäjä tallentaa lomakkeelle täyttämät tiedot ja lähettää ne toisen käyttäjän nähtäväksi, tehtäväksi tai käsiteltäväksi.'
        },
        {
          title: 'Tallenna luonnoksena -toiminto',
          description:
            'Toimintopainike, jolla käyttäjä tallentaa lomakkeelle täytetyt tiedot luonnos-tilaisena ja poistuu näkymästä.'
        },
        {
          title: 'Esikatsele -toiminto',
          description:
            'Toimintopainike, jolla käyttäjä voi tarkistaa miltä julkaistavien tietojen asettelu näyttää katselunäkymässä.'
        },
        {
          title: 'Jatka myöhemmin -toiminto',
          description:
            'Toimintopainike, jolla käyttäjä keskeyttää tietojen täyttämisen väliaikaisesti ja poistuu näkymästä. Tiedot tallennetaan tietokantaan ja niiden täyttämistä voi jatkaa myöhemmin.'
        },
        {
          title: 'Keskeytä-toiminto',
          description:
            'Toimintopainike, jolla käyttäjä keskeyttää tietojen täyttämisen ja poistuu näkymästä. Tiedot poistetaan tietokannasta.'
        },
        { title: '-' },
        {
          title: 'Käännökset',
          description:
            'Style Guiden komponenttien sisältämät yleiset käännökset, joilla helpotetaan komponenttien käyttöönottoa.'
        },
        {
          title: 'Virheviestit',
          description:
            'Työmarkkinatorilla tällä hetkellä käytössä olevat yleiset virheviestit.'
        }
      ],
      'patternit'
    )
  },
  {
    title: 'Typografia',
    to: '/typografia/',
    icon: LettersIcon,
    children: getChildren(
      [
        {
          title: 'Blockquote',
          description:
            'Tekstinosto, jolla sisällöstä poimitaan asian tiivistävä lainaus'
        },
        {
          title: 'Heading',
          description: 'Näkymän tai sen osion nimeävä otsikko'
        },
        {
          title: 'Highlight',
          description: 'Tekstin osan korostus'
        },
        {
          title: 'Lead',
          description: 'Näkymän otsikkoa tukeva korostettu tekstikappale'
        },
        {
          title: 'Paragraph',
          description: 'Leipätekstikappale'
        },
        {
          title: 'SmallText',
          description: 'Tekstikappale pienennetyllä pistekoolla'
        },
        {
          title: 'Text',
          description:
            'Geneerinen tekstikomponentti lyhyiden tekstien asetteluun ja muotoiluun'
        },
        {
          title: 'Typography',
          description:
            'Määrittelee HTML-elementeille sisältötekstin asettelua parantavia tyylejä.'
        }
      ],
      'typografia'
    )
  },
  {
    title: 'Asettelu',
    to: '/asettelu/',
    icon: BoxesIcon,
    children: getChildren(
      [
        {
          title: 'Sivupohjat',
          description:
            'Määrittelevät sivun kokonaisrakenteen, kuten sivupalkin ja sisältöalueen leveydet eri näyttökoissa.'
        },
        { title: '-' },
        {
          title: 'Columns, ColumnsInner, Main, Wrapper',
          description: 'Käytetään määritelemään sivun kokonaisrakennetta.'
        },
        {
          title: 'Container, Row, Col',
          description:
            'Käytetään näkymän jaottelussa alueiksi, riveiksi ja sarakkeiksi.'
        },
        {
          title: 'GridList',
          description: 'Käytetään korttimuotoisten linkkilistojen esittämiseen'
        },
        {
          title: 'Inline',
          description:
            'Järjestää komponentin sisään asetetut elementit riviin halutulla välillä.'
        },
        {
          title: 'PageBreak',
          description:
            'Määrittelee miten sisältö jakautuu sivuiksi näkymää tulostettaessa.'
        },
        {
          title: 'Spacer',
          description: 'Käytetään luomaan tilaa komponentin eri sivuille.'
        },
        {
          title: 'Stack',
          description:
            'Järjestää komponentin sisään asetetut elementit allekkain halutulla välillä.'
        }
      ],
      'asettelu'
    )
  },
  {
    title: 'Sisältö',
    to: '/sisalto/',
    icon: FileTextIcon,
    children: getChildren(
      [
        {
          title: 'Badge',
          description:
            'Pieni korostettu asiasana, jolla merkitään tiedon ominaisuuksia, kuten tilaa, tyyppiä tai luokittelua.'
        },
        {
          title: 'Box',
          description:
            'Geneerinen laatikko, jolla rytmitellään sisältöä toisistaan erottuviksi kokonaisuuksiksi.'
        },
        {
          title: 'BoxGroup',
          description:
            'Sulauttaa toisiinsa liittyvät laatikot yhtenäiseksi laatikkoelementiksi.'
        },
        {
          title: 'Bullet',
          description:
            'Ikonin, numeron tai hyvin lyhyen tekstin visuaalinen korostus.'
        },
        {
          title: 'Collapsible',
          description:
            'Painikkeesta avautuva sisältöalue, jonka sisään voidaan piilottaa käyttäjälle vähemmän oleellista tai paljon tilaa vievää sisältöä.'
        },
        {
          title: 'ColumnConnector',
          description: 'Kehysviiva, joka jakaa laatikkoelementin palstoihin.'
        },
        {
          title: 'Detail',
          description: 'Otsikko-arvo-pari, johon voidaan liittää ikoni.'
        },
        {
          title: 'Divider',
          description: 'Väliviiva, joka jakaa sisältöä kokonaisuuksiksi.'
        },
        {
          title: 'EmptyState',
          description: 'Ilmoitus ladattavan sisällön tai datan puuttumisesta.'
        },
        {
          title: 'Help',
          description:
            'Hoksautus, joka tarjoaa käyttäjälle kontekstisidonnaista lisätietoa, vinkkejä tai huomioita.'
        },
        {
          title: 'Message',
          description:
            'Sisältöön tai asian tilaan liittyvä viesti, ilmoitus tai huomautus.'
        },
        {
          title: 'Modal',
          description: 'Näkymän päälle avautuva dialogi'
        },
        {
          title: 'Time',
          description: 'Tekninen komponentti päivämäärän ja ajan esittämiseen'
        },
        {
          title: 'Tooltip',
          description:
            'Käyttöliittymäelementin käyttötarkoituksen selittävä ohje, joka näytetään kun kursori on elementin päällä.'
        },
        {
          title: 'VisuallyHidden',
          description:
            'Ruudunlukuohjelmalle luettava, visuaalisesti piilotettu teksti'
        }
      ],
      'sisalto'
    )
  },
  {
    title: 'Kuvat ja videot',
    to: '/kuvat-ja-videot/',
    icon: ImageIcon,
    children: getChildren(
      [
        { title: 'Yleiskatsaus' },
        {
          title: 'Ikonit',
          description:
            'Merkitykseltään vakioidut kuvalliset merkit, joilla selkiytetään näkymän visuaalista rakennetta, tuetaan käyttäjää sisällön tulkinnassa ja ohjaataan käyttäjän huomiota.'
        },
        { title: '-' },
        {
          title: 'Gallery',
          description: 'Kuvista koostuva ruudukko'
        },
        {
          title: 'Image',
          description:
            'Kuva tai kuvitus, joka tukee käyttäjää sisällön tulkinnassa, tekee sisällöstä helpommin lähestyttävän tai muuten elävöittää näkymää.'
        },
        {
          title: 'Logo',
          description: 'Työmarkkinatorin logot'
        }
      ],
      'kuvat-ja-videot'
    )
  },
  {
    title: 'Palautteet',
    to: '/palautteet/',
    icon: DialogueIcon,
    children: getChildren(
      [
        {
          title: 'Alert',
          description:
            'Varoitus näkymän sisältöön liittyvästä tai toiminnon suorittamisen estävästä merkittävästä virheestä'
        },
        {
          title: 'LoadingOverlay',
          description:
            'Ladattavan sisällön paikalla näytettävä animaatio, joka kertoo käyttäjälle taustalla käynnissä olevasta latausoperaatiosta.'
        },
        {
          title: 'NotFoundPage',
          description: 'Virheilmoitus puuttuvasta sisällöstä (404 Not found)'
        },
        {
          title: 'NotificationBar',
          description: 'Käytä tämän sijasta SiteNotifications -komponenttia'
        },
        {
          title: 'Notifications',
          description:
            'Näkymän ylälaidassa näytettävä väliaikainen ponnahdusilmoitus'
        },
        {
          title: 'Progress',
          description:
            'Latauspalkki, joka viestii käyttäjälle taustalla käynnissä olevan tiedoston tai muun kestoltaan selkästi rajatun datapaketin latausoperaation etenemisestä.'
        },
        {
          title: 'SiteNotifications',
          description:
            'Pysyvä ilmoituspalkki koko sivustoa koskevia ilmoituksia, kuten huoltokatkoja ja evästebanneria varten. Korvaa NotificationBarin.'
        },
        {
          title: 'Spinner',
          description:
            'Animaatio, joka viestiin käyttäjälle taustalla käynnissä olevasta, kestoltaan määrittelemättömän mittaisesta latausoperaatiosta.'
        },
        {
          title: 'StatusBar',
          description:
            'Sisältöalueen alalaitaan sijoitettu palkki, jossa käyttäjälle kerrotaan näkymän tilaan liittyvää tietoa'
        },
        {
          title: 'UnauthorizedPage',
          description:
            'Virheilmoitus, jolla kerrotaan että käyttäjällä ei ole oikeutta tarkastella sisältöä (401 Unauthorized)'
        }
      ],
      'palautteet'
    )
  },
  {
    title: 'Listat ja taulukot',
    to: '/listat-ja-taulukot/',
    icon: ListUnorderedIcon,
    children: getChildren(
      [
        {
          title: 'DescriptionList',
          description:
            'Kahteen palstaan vierekkäin aseteltu lista otsikko–arvo-pareja'
        },
        {
          title: 'Feed',
          description: 'Komponentit tapahtumavirtanäkymän kokoamiseen'
        },
        {
          title: 'InlineList',
          description: 'Pystyviivoilla ryhmitelty listarivi'
        },
        {
          title: 'List',
          description: 'Luettelomerkeillä ryhmitelty lista'
        },
        {
          title: 'SearchResult',
          description: 'Työmarkkinatorin sivustonlaajuisen haun hakutulos'
        },
        {
          title: 'Table',
          description: 'Komponentit taulukoiden asetteluun'
        },
        {
          title: 'TaskList',
          description:
            'Tehtävälista, jolla listataan prosessiin liittyviä tehtäviä ja ilmaistaan mitkä tehtävistä käyttäjä on jo suorittanut.'
        },
        {
          title: 'Timeline',
          description: 'Aikajana, jolla listataan tapahtumia aikajärjestyksessä'
        },
        {
          title: 'UploadFileList',
          description:
            'Komponentit ladattavien liitetiedostojen listaukseen ja muokkaamiseen'
        }
      ],
      'listat-ja-taulukot'
    )
  },
  {
    title: 'Toiminnot',
    to: '/toiminnot/',
    icon: HandIcon,
    children: getChildren(
      [
        {
          title: 'ActionButton',
          description:
            'Toimintakehoite (CTA) ohjaa käyttäjää siirtymään oleellista lisäinformaatiota tai jatkotoimintoja tarjoavaan näkymään.'
        },
        {
          title: 'ActionButtonGroup',
          description:
            'Kokoaa yhteen toisiinsa liittyvät toimintakehoitteet yhteisen otsikon alle.'
        },
        {
          title: 'Button',
          description: 'Painike, jolla käyttäjä suorittaa jonkin toiminnon'
        },
        {
          title: 'ButtonGroup',
          description:
            'Painikeryhmä, jolla käyttäjä voi valita yhden vaihtoehtoisista toiminnoista'
        },
        {
          title: 'Buttons',
          description: 'Kokoaa yhteen toisiinsa liittyvät toimintopainikkeet'
        },
        {
          title: 'Chat',
          description:
            'Näkymän päälle avautuva chatti-ikkuna, jolla käyttäjä voi lähettää ja vastaanottaa viestejä'
        },
        {
          title: 'CircleButton',
          description:
            'Pieni, pyöreä ja tekstitön ikoni-painike, jolla käyttäjä voi suorittaa jonkin toiminnon'
        },
        {
          title: 'Dropdown',
          description:
            'Painikkeesta avautuva toimintovalikko, jolla käyttäjälle tarjotaan vaihtoehtoisia toimintoja'
        },
        {
          title: 'SquareButton',
          description:
            'Kulmikas painike, jolla käyttäjä suorittaa jonkin toiminnon'
        }
      ],
      'toiminnot'
    )
  },
  {
    title: 'Navigointi',
    to: '/navigointi/',
    icon: ExternalLinkIcon,
    children: getChildren(
      [
        {
          title: 'Breadcrumb',
          description:
            'Navigaatioelementti, joka auttaa käyttäjää hahmottamaan sivun sijoittumista sivuhierarkiassa ja navigoimaan hierarkian tasojen välillä'
        },
        {
          title: 'Footer',
          description: 'Sivuston kaikille näkymille yhteinen alatunnistepalkki'
        },
        {
          title: 'Header',
          description:
            'Sivuston kaikille näkymille yhteinen ylätunniste- ja navigaatiopalkki'
        },
        {
          title: 'Link',
          description:
            'Tekstilinkki, jolla käyttäjä voi navigoida toiseen näkymään tai sivustolle'
        },
        {
          title: 'Pagination',
          description: 'Hakutuloksia tai muuta dataa listaavan näkymän sivutus'
        },
        {
          title: 'SideNav',
          description:
            'Näkymän sivupalkkissa näytettävä navigaatio, jolla käyttäjä voi siirtyä alasivujen välillä'
        },
        {
          title: 'SideSteps',
          description:
            'Monivaiheisen lomakkeen sivupalkissa näytettävä navigaatio'
        },
        {
          title: 'SkipLink',
          description:
            'Näkymässä ensimmäisenä tab-fokuksen saava linkkipainike, jolla näppäimistökäyttäjä voi ohittaa sivuston navigaation ja hypätä suoraan näkymän sisältöön'
        },
        {
          title: 'SomeButton',
          description:
            'Linkkipainikkeet, joilla käyttäjä ohjataan siirtymään Työmarkkinatorin sosiaalisen median kanaviin'
        },
        {
          title: 'TabList',
          description:
            'Näkymän sisällön erillisiksi välilehdiksi jakava komponentti'
        },
        {
          title: 'Tabs',
          description:
            'Näkymän sisällön erillisiksi välilehdiksi jakava lisänavigaatio'
        }
      ],
      'navigointi'
    )
  },
  {
    title: 'Lomakkeet',
    to: '/lomakkeet/',
    icon: PenIcon,
    children: getChildren(
      [
        {
          title: 'FormLayout',
          description:
            'Käytetään lomakkeen kokoamiseen, osiointiin ja otsikointiin.'
        },
        {
          title: 'FormGroup',
          description:
            'Käytetään kokoamaan useita lomake-elementtejä yhteisen otsikon alle.'
        },
        { title: '-' },
        {
          title: 'Checkbox',
          description:
            'Valintaruutu, jolla käyttäjä voi merkitä yksittäisen arvon valituksi tai valita samanaikaisesti useampia vaihtoehtoja valintaruuturyhmästä'
        },
        {
          title: 'CheckboxButton',
          description:
            'Painikkeen muodossa esitetty Checkbox tai RadioButton, jolla korostetaan käyttäjälle oleellista valintaa'
        },
        {
          title: 'DateInput',
          description:
            'Lomakekenttä ja siitä avautuva kalenteri, jolla käyttäjä voi syöttää tai valita päivämäärän'
        },
        {
          title: 'DateRangeSelect',
          description:
            'Dialogiin avautuva lomakekenttä- ja kalenteripari, jolla käyttäjä voi syöttää tai valita ajanjakson alkupäivän ja loppupäivän'
        },
        {
          title: 'DateSelect',
          description:
            'Lomakekenttäpari, jolla käyttäjä voi valita kuukauden ja vuoden tai koko päivämäärän'
        },
        {
          title: 'DateTimeInput',
          description:
            'Lomakekenttäpari, jolla käyttäjä voi syöttää tai valita päivämäärän ja kellonajan'
        },
        {
          title: 'Dropzone',
          description:
            'Tiedoston latauskomponentti, jolla käyttäjä voi valita serverille ladattavan liitetiedoston joko raahamalla sen komponentin päälle tai valitsemalla käyttöjärjestelmän tiedostohallintanäkymästä.'
        },
        {
          title: 'Filter',
          description:
            'Painikeryhmä, jolla käyttäjä voi valita sisältöä rajaavia ehtoja'
        },
        {
          title: 'FormError',
          description: 'Lomakekentän yhteyteen liitettävä virheviestin teksti.'
        },
        {
          title: 'FormIndent',
          description:
            'Pystyviivalla korostettu sisennys, joka ryhmittelee samaan labeliin tai lomake-elementtiin liittyvät alisteiset lisäkentät yhteen'
        },
        {
          title: 'FormText',
          description: 'Lomakekentän yhteyteen liitettävä teksti'
        },
        {
          title: 'InlineLabel',
          description: 'Lomakekentän vasemmalle puolelle aseteltu otsikko'
        },
        {
          title: 'Input',
          description: 'Tekstikenttä, jolla käyttäjä voi syöttää lyhyen tekstin'
        },
        {
          title: 'Label',
          description:
            'Erikoiskomponentti lomakekentän otsikolle, joka kertoo käyttäjälle selkeästi ja lyhyesti kenttään täytettävän tiedon nimen'
        },
        {
          title: 'NumberInput',
          description:
            'Lomakekenttä, jolla käyttäjä voi syöttää tai valita lukumäärän'
        },
        {
          title: 'PlainSelect',
          description:
            'Visuaalisesti riisuttu versio pudotusvalikosta, jolla käyttäjä voi valita yhden useasta vaihtoehdosta'
        },
        {
          title: 'RadioGroup',
          description:
            'Valintapainikeryhmä, jolla käyttäjä voi valita kerrallaan yhden tarjotuista vaihtoehtoehdoista'
        },
        {
          title: 'Rating',
          description:
            'Valintapainikeryhmä, jolla käyttäjä voi valita lukuarvon annetulta arvosteluasteikolta'
        },
        {
          title: 'Required',
          description: 'Merkintä lomakentän pakollisuudesta'
        },
        {
          title: 'RichTextarea',
          description:
            'Monirivinen tekstialue, johon käyttäjä voi kirjoittaa pidemmän tekstin ja vaikuttaa sen muotoiluun'
        },
        {
          title: 'Select',
          description:
            'Pudotusvalikko, jolla käyttäjä voi valita yhden useasta vaihtoehdosta'
        },
        {
          title: 'Switch',
          description:
            'Valintakytkin, jolla käyttäjä voi valita kahden toisensa pois sulkevan tilan väliltä'
        },
        {
          title: 'Tag',
          description:
            'Pieni korostettu asiasana, jolla listataan käyttäjän lomakekomponentista tekemiä valintoja tai valittavissa olevia vaihtoehtoja'
        },
        {
          title: 'Textarea',
          description:
            'Monirivinen tekstialue, johon käyttäjä voi kirjoittaa pidemmän tekstin'
        },
        {
          title: 'TimeInput',
          description:
            'Lomakekenttä, jolla käyttäjä voi syöttää tai valita painikkeesta avautuvasta valikosta kellonajan'
        }
      ],
      'lomakkeet'
    )
  },
  {
    title: 'Muut',
    to: '/muut/',
    icon: PuzzlePieceIcon,
    children: [
      ...getChildren(
        [
          {
            title: 'CSSReset',
            description:
              'Tekninen komponentti, jolla nollataan HTML-tagien tyylit'
          },
          {
            title: 'Focus',
            description:
              'Tekninen komponentti, jolla ohjataan näppäimistöfocus tiettyyn elementtiin näkymään saavuttaessa'
          },
          {
            title: 'Provider',
            description:
              'Tekninen komponentti, jolla määritellään näkymän lokalisointi'
          },
          {
            title: 'useBreakpoint',
            description:
              'Tekninen komponentti, jolla määritellään käytettävä Breakpoint'
          },
          { title: '-' }
        ],
        'muut'
      ),
      {
        title: 'UI Common Components',
        to: '/ui-common-components/',
        external: true
      },
      {
        title: 'Työnhakuprofiili',
        to: '/tyonhakuprofiili/latest/',
        external: true
      }
    ]
  },
  {
    title: 'Verkkopalvelu',
    to: '/verkkopalvelu/',
    icon: StarIcon,
    children: getChildren(
      [
        { title: 'CSS-luokat' },
        { title: '-' },
        { title: 'Accordion' },
        { title: 'BubbleImage' },
        { title: 'BubbleList' },
        { title: 'ContentBackground' },
        { title: 'Embed' },
        { title: 'FeedbackButton' },
        { title: 'Figure' },
        { title: 'HeroSection' },
        { title: 'ImageCard' },
        { title: 'ImageSection' },
        { title: 'ImageSectionWide' },
        { title: 'LinkNav' },
        { title: 'Term' },
        { title: 'TestimonialCard' },
        { title: 'ThemeCard' }
      ],
      'verkkopalvelu'
    )
  },
  {
    title: 'Mallit',
    to: '/mallit/',
    icon: CheckCircleIcon
  }
]

const flattenPages = (pages: Page[]): Page[] =>
  pages.reduce((prev, curr) => {
    if (curr.children) {
      return prev.concat(flattenPages(curr.children))
    }

    prev.push(curr)

    return prev
  }, [] as Page[])

export const FLAT_PAGES = flattenPages(PAGES)
