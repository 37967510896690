import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { Spacer } from '../Spacer/Spacer.js';

const getRadius = radius => radius ? tokens.radius[radius] : undefined;
const StyledBoxGroup = styled.div.withConfig({
  componentId: "sg16120__sc-1eqvdek-0"
})(["border-radius:", ";display:flex;flex-direction:column;transition:box-shadow ", ";width:100%;&,&:hover{color:inherit;text-decoration:none;}&:focus{box-shadow:", ";outline:none;}"], tokens.radius.md, tokens.transition.fast, tokens.shadow.focus);
const getBackgroundColor = backgroundColor => {
  if (backgroundColor === 'light') {
    return tokens.color['neutral-2'];
  }
  return tokens.color.white;
};
const getBorderColor = borderColor => {
  if (borderColor) {
    if (borderColor === 'dark') {
      return tokens.color['neutral-7'];
    }
    if (borderColor === 'disabled') {
      return tokens.color['neutral-5'];
    }
    return tokens.color[borderColor];
  }
  return tokens.color['neutral-4'];
};
const StyledBox = styled(Spacer).withConfig({
  componentId: "sg16120__sc-1eqvdek-1"
})(["background-color:", ";border-color:", ";border-style:", ";border-width:1px;border-radius:", ";box-shadow:", ";color:", ";flex:", ";overflow:", ";position:relative;width:100%;", " ", " a", ":hover &{border-left-color:", ";border-right-color:", ";}", " &:first-child:not(:last-child){border-bottom-left-radius:0;border-bottom-right-radius:0;margin-bottom:-1px;}", " &:not(:first-child):not(:last-child){border-radius:0;margin-bottom:-1px;}", " &:last-child:not(:first-child){border-top-left-radius:0;border-top-right-radius:0;}a", ":hover &:first-child{border-top-color:", ";}a", ":hover &:last-child{border-bottom-color:", ";}"], props => getBackgroundColor(props.$color), props => getBorderColor(props.$borderColor), props => props.$border || 'solid', props => getRadius(props.$radius) || tokens.radius.md, props => props.$shadow && tokens.shadow[props.$shadow], tokens.color['neutral-9'], props => props.$grow ? '1 1 auto' : '0 0 auto', props => props.$overflow, props => css(["border-bottom-left-radius:", ";border-bottom-right-radius:", ";border-top-left-radius:", ";border-top-right-radius:", ";"], getRadius(props.$radiusBottomLeft), getRadius(props.$radiusBottomRight), getRadius(props.$radiusTopLeft), getRadius(props.$radiusTopRight)), props => props.$height && css(["height:", "px;"], props.$height), StyledBoxGroup, tokens.color.primary, tokens.color.primary, StyledBoxGroup, StyledBoxGroup, StyledBoxGroup, StyledBoxGroup, tokens.color.primary, StyledBoxGroup, tokens.color.primary);
const StyledBoxAccessory = styled.div.withConfig({
  componentId: "sg16120__sc-1eqvdek-2"
})(["position:absolute;top:-1rem;right:-1rem;"]);

export { StyledBox, StyledBoxAccessory, StyledBoxGroup };
